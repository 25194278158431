// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/hifilorau/code/williams-robotics/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("/Users/hifilorau/code/williams-robotics/src/pages/Contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/Users/hifilorau/code/williams-robotics/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/hifilorau/code/williams-robotics/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-products-index-js": () => import("/Users/hifilorau/code/williams-robotics/src/pages/Products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-team-index-js": () => import("/Users/hifilorau/code/williams-robotics/src/pages/Team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/hifilorau/code/williams-robotics/.cache/data.json")

